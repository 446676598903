import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import moment from "moment"

Vue.use(Vuex)

const uTrackUrl = "https://584i7lz3vc.execute-api.ap-southeast-1.amazonaws.com/dev/v1/utrack";
// const uTrackUrl = "http://localhost:3000/dev/v1/utrack";

export default new Vuex.Store({
  state: {
    trackingNo: null,
    shippingMethod: null,
    status: null,
    trackingsData: null
  },
  getters: {
    trackingsData(state) {
      return state.trackingsData;
    }
  },

  mutations: {
    setTrackingNo(state, trackingNo) {
      state.trackingNo = trackingNo;
    },
    setTrackingsData(state, trackingsData) {
      if (!trackingsData) return;
      if (trackingsData && !trackingsData.sm) return;

      if (!trackingsData.trackings || (trackingsData.trackings && !trackingsData.trackings.length))
        trackingsData.trackings = [{
          barcode: state.trackingNo,
          statusDate: trackingsData.createdAt,
          statusDescription: 'ผู้ส่งกำลังเตรียมพัสดุ'
        }]

      if (!trackingsData || (trackingsData && !trackingsData.trackings) || (trackingsData && trackingsData.trackings && !trackingsData.trackings.length)) {
        state.trackingsData = null;
        if (trackingsData.sm) state.shippingMethod = trackingsData.sm;
        return;
      }

      state.shippingMethod = trackingsData.sm;
      state.status = trackingsData.status;

      let dtPattern = "DD/MM/YYYY HH:mm:ss";
      state.trackingsData = trackingsData.trackings.map((d) => {
        let dateTimeParsed = moment(d.statusDate, dtPattern);
        return {
          station: d.station,
          desc: d.statusDescription,
          date: dateTimeParsed.format("Do MMM YYYY"),
          time: dateTimeParsed.format("HH:mm:ss"),
          timestamp: dateTimeParsed.valueOf(),
        };
      })
        .sort((d1, d2) => d2.timestamp - d1.timestamp);
    }
  },
  actions: {
    async fetchTrackings({ state, commit, rootState }, payload) {
      try {
        let resp = await axios.get(`${uTrackUrl}?barcode=${state.trackingNo}`);

        // fix for kerry
        if (resp.data && resp.data.sm === "kerry") {
          if (resp.data.trackings && resp.data.trackings.length) {
            if (resp.data.trackings[0].statusDescription === "ไม่พบหมายเลขพัสดุ") {
              resp.data.trackings[0].statusDescription = "พัสดุกำลังรอทางขนส่งรับเข้าระบบ"
              resp.data.trackings[0].statusDate = new Date().toLocaleString('en-GB', { hour12: false }).replace(",", "")
            }
          }
        }

        commit("setTrackingsData", resp.data);
        payload.callback(true);
      } catch (e) {
        console.log(e);
        if (e.respose && e.response.data) console.log(e.response.data);
        payload.callback(false);
        commit("setTrackingsData", null);
      }
    }
  },
  modules: {
  },
  // plugins: [vuexLocal.plugin]
})
